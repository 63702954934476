<template>
  <div>
    <Header />
    <div class="bg-pattern bg-center">
      <main class="p-4 py-20 min-h-screen max-w-6xl mx-auto">
        <img src="@/assets/logo.png" class="w-64 mx-auto h-auto" />

        <div class="flex space-x-4 mt-20 max-w-2xl mx-auto">
          <div
            class="flex-1 bg-white border border-black text-center pb-4 relative"
          >
            <h2
              class="bg-gray-300 text-center py-4 uppercase text-black font-bold text-lg"
            >
              Password reset
            </h2>

            <form class="space-y-4 text-left p-6">
              <label class="block">
                <span class="block mb-2">email</span>
                <input
                  class="border border-black p-4 w-full"
                  type="email"
                  v-model="email"
                />
              </label>

              <template v-if="haveCode">
                <label class="block">
                  <span class="block mb-2">password</span>
                  <input
                    class="border border-black p-4 w-full"
                    type="password"
                    v-model="password"
                  />
                </label>

                <label class="block">
                  <span class="block mb-2">retype password</span>
                  <input
                    class="border border-black p-4 w-full"
                    type="password"
                    v-model="confirmPassword"
                  />
                </label>
                <p class="text-sm my-4  text-left" v-if="!error">
                  Passwords must contain at least an upper case letter and at
                  least one number
                </p>
              </template>
              <p class="text-sm my-4 text-red-600 text-left" v-if="error">
                {{ error }}
              </p>
              <div class="text-center">
                <span v-if="!submitting">
                  <button
                    type="submit"
                    v-on:click="testReset"
                    class="bg-gray-300 border  mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative"
                  >
                    Reset Password
                    <div
                      class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                    ></div>
                  </button>
                </span><span v-else>
                  <button
                    class="bg-gray-300 border  mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative opacity-50 cursor-not-allowed pointer-events-none"
                  >
                    <span v-if="sending"
                      ><template v-if="haveCode">Resetting password</template
                      ><template v-else>Sending email</template></span
                    >
                    <span v-if="sent"
                      ><template v-if="haveCode">Reset!</template
                      ><template v-else>{{
                        sent === true ? "Sent!" : sent
                      }}</template></span
                    >
                    <div
                      class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                    ></div>
                  </button>
                </span>
              </div>
            </form>
            <div
              class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
            ></div>
          </div>
        </div>
      </main>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import API from "./api";

export default {
  name: "ResetPassword",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      submitting: false,
      error: false,
      sent: false,
      sending: false,
    };
  },

  computed: {
    haveCode() {
      return this.$route.query.code;
    },
    haveEmail() {
      return this.$route.query.email;
    },
  },
  mounted() {
    if (this.haveEmail) {
      this.email = this.haveEmail;
    }
  },

  methods: {
    testReset(event) {
      event.preventDefault();
      if (this.email.length) {
        this.submitReset();
      } else {
        this.error = "Please enter an email address.";
      }
    },
    async submitReset() {
      this.submitting = true;
      this.sending = true;

      if (this.haveCode) {
        if (this.password && this.email) {
          if (this.password != this.confirmPassword) {
            this.sent = false;
            this.sending = false;
            this.submitting = false;
            this.error =
              "Please make sure your password confirmation is the same";
            return;
          }
          let data = {
            email: this.email,
            password: this.password,
            code: this.haveCode,
          };

          API.post(
            "/user/password/forgot/confirm",
            false,
            data
          )
            .then(() => {
              this.sending = false;
              this.sent = "Logging in...";
              this.submitLogIn();
            })
            .catch((error) => {
              if (error.response.data) {
                this.sending = false;
                this.sent = false;
                this.submitting = false;
                this.error = error.response.data
                  ? error.response.data
                  : error.response.data.body;
              }
            });
        } else {
          this.error = "Please enter a new password";
          this.sent = false;
          this.sending = false;
          this.submitting = false;
          return;
        }
      } else {
        let data = {
          email: this.email,
        };

        API.post(
          "/user/password/forgot",
          false,
          data
        )
          .then(() => {
            this.sending = false;
            this.sent = true;
            setTimeout(() => {
              this.sent = false;
              this.sending = false;
              this.submitting = false;
              this.email = "";
            }, 2000);
          })
          .catch((error) => {
            if (error.response.data) {
              this.sending = false;
              this.sent = false;
              this.submitting = false;
              this.error = "User not found."
              // this.error = error.response.data
              //   ? error.response.data
              //   : error.response.data.body;
            }
          });
      }
    },
    async submitLogIn() {
      this.error = false;
      this.submitting = true;
      let data = {
        email: this.email,
        password: this.password,
      };

      API.post(
        "/user/login",
        false,
        data
      )
        .then((response) => {
          this.$store.commit("storeAccessTokens", response.data);

          let auth = {
            idToken: this.$store.getters.accessTokens.id_token,
          };
          API.get(
            "/user",
            auth
          )
            .then((response) => {
              this.$store.commit(
                "storeSubscriptionType",
                response.data.subscription
              );
              this.$store.commit("storeUserID", response.data.user_id);

              this.$store.commit(
                "storeCustomerID",
                response.data.stripe_customer_id
              );

              window.location = window.location.origin + "/predictions";
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          if (error.response.data) {
            this.submitting = false;
            this.error = error.response.data.body
              ? error.response.data.body
              : error.response.data;
          }
        });
    },
  },
};
</script>

<style></style>
